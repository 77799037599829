<template>
  <v-list
    class="pa-0 ma-0 fonte"
    :dark="$theme.dark"
    :light="$theme.light"
    two-line
    dense
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="(withdraw, i) in showFiltereds">
      <v-list-item
        @click="() => {}"
        style="border-radius: 6px;"
        :key="withdraw._id"
        class="fonte pl-2 pr-1 animate__animated animate__fadeInUp"
      >
        <v-avatar color="#f2f2f2" class="mr-2">
          <v-icon :color="$theme.primary">mdi-cash-multiple</v-icon>
        </v-avatar>
        <v-list-item-content class="">
          <v-list-item-title class="fonte" style="font-size: 12pt;">
            {{ $helper.formataSaldo(withdraw.value) }}
          </v-list-item-title>
          <v-list-item-subtitle class="fonte fonteMini">
            <v-icon size="14" :color="$theme.primary">mdi-bank</v-icon>
            {{ withdraw.bank_account.bank }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="fonte fonteMini">
            <v-icon size="14" :color="$theme.primary">mdi-calendar-clock</v-icon>
            {{ $moment(withdraw.created_at).format('DD/MM [às] HH:mm') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="withdraw.status == 'waiting'" class="orange--text">
            AGUARDANDO PAGAMENTO
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="withdraw.status == 'approved'" class="green--text">
            PAGO
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="withdraw.status == 'reproved'" class="red--text">
            NEGADO
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="i < showFiltereds.length - 1" :key="i"></v-divider>
    </template>
    <v-list-item
      style="min-height: 200px;"
      v-intersect="onIntersect"
    ></v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    },
    onlyEmitInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemsToShow: 12
    };
  },
  computed: {
    ...mapGetters(["get_withdraw"]),
    showFiltereds() {
      return this.filtereds.slice(0, this.itemsToShow);
    }
  },
  methods: {
    onIntersect() {
      this.itemsToShow += 12;
    },
    open(client) {
      if (this.onlyEmitInput) {
        this.$emit('input', client);
        return;
      }
      this.$store.commit("set_withdraw", client);
    }
  }
};
</script>
